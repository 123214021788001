.visualizer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.circle-center {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  z-index: 1;
  animation: pulse-frame 6s ease-in-out infinite;
  transition: transform 0.2s ease-in;
}

.picture {
  position: absolute;
  margin: 10px;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  z-index: 3;
}

.wave {
  border-radius: 50%;
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(164, 54, 232, 0.5) 50%,
    rgba(183, 253, 254, 0.5) 100%
  );
  animation: move-wave 4s linear infinite;
  transition: transform 2.5s ease-in-out;
}

@keyframes pulse-frame {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes move-wave {
  0% {
    transform: translateY(0) scale(1);
  }
  25% {
    transform: rotate(90deg) scale(1.2);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  75% {
    transform: rotate(270deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.circle-center:hover {
  animation: pulse-frame-plus 1s ease-in-out infinite;
  transform: scale(4);
  transition: transform 0.2s ease-in;
  animation-fill-mode: forwards;
}

@keyframes pulse-frame-plus {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
