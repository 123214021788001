.bubbleR {
  animation: float 6s infinite;
  transition: transform 0.5s ease-in-out;
  color: #0072b1;
}

.iconR {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
}

.bubbleR:hover > .iconR {
  color: white;
  background-color: #0072b1;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.bubbleL {
  animation: floatm 7s infinite;
  transition: transform 0.5s ease-in-out;
  color: white;
}

.iconL {
  padding: 5px;
  background-color: #0072b1;
  border-radius: 10px;
}

.bubbleL:hover > .iconL {
  color: #0072b1;
  background-color: white;
}

@keyframes floatm {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}
