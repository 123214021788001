.projectTile {
  background: linear-gradient(
    to top left,
    rgb(0, 0, 0, 0.25),
    rgb(77, 77, 77, 0.25)
  );
  backdrop-filter: blur(5px);
  padding: 1rem;
  cursor: pointer;
  border-radius: 50px;
  position: relative;
}

.projectTile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 1px solid transparent;
}

.projectTile:hover::before {
  border-color: rgba(99, 99, 99, 0.5);
}
