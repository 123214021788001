.containerHome {
  /* set initial size and position */
  width: 100px;
  height: 100px;
  position: relative;
  left: 50%;
  top: 55vh;
  justify-content: center;
}

/* apply scaling based on viewport width */

@media only screen and (max-width: 767px) {
  .containerHome {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .containerHome {
    transform: translate(-50%, -50%) scale(1.4);
  }
}

@media only screen and (min-width: 1024px) {
  .containerHome {
    transform: translateX(-50%) scale(1.6);
  }
}
