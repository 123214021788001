.projectTile2 {
  background: linear-gradient(
    to top left,
    rgb(0, 0, 0, 0.25),
    rgb(77, 77, 77, 0.25)
  );
  backdrop-filter: blur(5px);
  padding: 1rem;
  border-radius: 50px;
  position: relative;
}

.gif {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.gif img {
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.linkButton {
  color: #121212;
  background: radial-gradient(
    circle,
    rgba(221, 174, 238, 0.75) 0%,
    rgba(148, 233, 231, 0.6) 100%
  );
  border-radius: 10px;
  padding: 5px 15px;
  margin: 5px;
  text-decoration: none;
  z-index: 5;
}
