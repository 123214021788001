.chartBoxOuter {
  position: relative;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}

.chartBoxInner {
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 575px;
}

.chartPoints {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

@keyframes spawn {
  0% {
    opacity: 0;
    animation-delay: 0s; /* Add animation-delay here */
  }
  50% {
    opacity: 0.6;
    animation-delay: 2s; /* Add animation-delay here */
  }
  100% {
    opacity: 1;
    animation-delay: 4s; /* Add animation-delay here */
  }
}

/*.points:hover {
  transform: translateY(3%);
  transition: transform 2.5s ease-in;
}*/
