@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Sora:wght@200&display=swap");

body {
  margin: 0;
  background: #121212;
  font-family: -apple-system, "Oswald", "Sora", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #232526;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
